import momemt from 'moment';
import 'slick-carousel';
import 'magnific-popup';
jQuery( document ).ready(function($) {
  // mobile menu
  $('.menu-trigger').click(function(){
    $(this).toggleClass('active')
    $('.logo, .menu').toggleClass('active')
    $('.popup').removeClass('active')
  })
  $('.menu a').click(function(){
    $('.logo, .menu').removeClass('active')
    $('.menu').removeClass('active')
    $('.popup.lyrics-menu').removeClass('active')
    $('.menu-trigger').removeClass('active')
  })
  $(window).scroll(function() {    
    var scroll = $(window).scrollTop();
    if (scroll >= 84) {
      $("header").addClass("scrolled");
    } else{
      $("header").removeClass("scrolled");
    }
  }); //missing );
  // slider
  $('.slider-music').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',
    responsive: [
      {
        breakpoint: 992,
        settings: 'unslick'
      }
    ]
  });
  $('.slider-video').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></button>',
    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></button>',

  });

  $('.slider-video a').magnificPopup({
    type: 'iframe'
  })
  // Events
  $.ajax( {
    url: 'https://rest.bandsintown.com/artists/Pj%20Harding/events?app_id=45PRESS_PJ_HARDING',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert( 'Error fetching events!' );
    },
    success: data => {
    const events = $( '#events' );
    let html = '';
    if ( data.length ) {
      for ( let event of data ) {
        let location = event.venue.city + ', ' + event.venue.region;
        if ( location === ', ' ) {location = '';}
        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}
        html += '<div class="event">';
        html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';
        html += '<div class="event-location">' + location + '</div>';
        html += '<div class="event-venue">' + event.venue.name + '</div>';
        html += '<div class="event-links">';
        for ( let offer of event.offers ) {
          html += '<a href="' + offer.url + '" target="_blank" class="btn btn-white-outline">' + offer.type + '</a>';
        }
        html += '</div>';
        html += '</div>';
      }
      events.html( html );
      } else {
        events.html( '<span class="empty">No upcoming events.</span>' );
      }
    }
  });
  // Newsletter
  $("#pj-harding-nl-signup").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        $('form').hide()
        $('.form-alert.error').hide()
        $('.form-alert.success').show()
      },
      error: function (err) {
        $('.form-alert.error').show()
      }
    });
  });
  // popup
  $('.close').click(function(){
    $(this).parent().parent().parent().removeClass('active')
    $('.menu').removeClass('active')
    $('.menu-trigger').removeClass('active')
    $('header .logo').removeClass('active')
  })
  // Lyrics
  $('.trigger-lyrics').click(function(){
    $('.popup.lyrics-menu').addClass('active')
    $('.logo, .menu').addClass('active')
    $('header').removeClass('scrolled')
    $('.menu').removeClass('active')
  })
  $('.lyrics-nav a').click(function(e){
    e.preventDefault()
    $('.popup.lyrics-menu').removeClass('active')
    const theTarget = $(this).attr('href')
    $(`#${theTarget}`).addClass('active')
  })
});